// Base url
// for local test, include url of main website with gallery as sub-domain
export const BASE_URL = "";
// Get album list
export const GET_ALBUM = "./getAlbum.php";
// Get Image list
export const GET_IMAGES = "./getImages.php";

export const GET_URL = (url: string) => {
  // returns based on url sent to this
  if (url === "mavvidyashram") {
    return "https://mavvidyashram.com/";
  } else {
    return "https://mymavinternational.com/";
  }
};
export const GET_LOGO = (url: string) => {
  // returns based on url sent to this
  if (url === "mavvidyashram") {
    return "https://mavvidyashram.com/static/media/logo.4e8eb528.png";
  } else {
    return "https://mymavinternational.com/vendor/img/mavlogoo.png";
  }
};

export const MAVVIDYASHRAM = "mavvidyashram";
export const MAVINTERNATIONAL = "mymavinternational";

export const ZIPPED_GALLERY_URL = "https://djvg3flwq0.execute-api.us-east-1.amazonaws.com/default/zipGallery";

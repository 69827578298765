import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  CircularProgress,
  Button,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import { bucketName, getImagesInFolder, listFoldersInS3 } from "../api/api";
import { GET_LOGO, MAVINTERNATIONAL, MAVVIDYASHRAM, ZIPPED_GALLERY_URL } from "../../utils/const";
import axios from 'axios';

const HeaderComp = (props: any) => {
  const {
    handleImages,
    year,
    setYear,
    selectedAlbum,
    setSelectedAlbum,
    isInternationalURL,
  } = props;

  const presentYear = new Date().getFullYear() + 1;
  const [loading, setLoading] = useState(false);
  let yearArray = [];
  for (let i = 1; i < 4; i++) {
    yearArray.push(presentYear - i);
  }

  const [gallery, setGallery] = useState([]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleYearChange = async (event: SelectChangeEvent) => {
    if (event.target.value !== "Select year") {
      setYear(event.target.value);
      setSelectedAlbum("");
      setGallery([]);
      setLoading(true);
      listFoldersInS3(event.target.value).then((response) => {
        setGallery(response);
      });
      setLoading(false);
    }
  };
  const handleSelectedGallery = async (event: SelectChangeEvent) => {
    await getImagesInFolder(year, event.target.value).then((response) => {
      setSelectedAlbum(event.target.value);
      handleImages(response);
    });
  };

  async function downloadFile(url: string, filename: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
    setLoading(false);
  }

  const getAlbum = async () => {
    try {

      setLoading(true);
      let response = await axios.get(`${ZIPPED_GALLERY_URL}?bucketName=${bucketName}&celebrationYear=${year}&celebrationName=${encodeURIComponent(selectedAlbum)}`);
      if (response) {
        downloadFile(response.data.downloadUrl, `${selectedAlbum}.zip`);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  const handleLogoDisplay = () =>
    GET_LOGO(isInternationalURL ? MAVINTERNATIONAL : MAVVIDYASHRAM);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1em",
        }}
      >
        <img
          style={{
            width: isInternationalURL ? "20vh" : "50vh",
            textAlign: "center",
          }}
          src={handleLogoDisplay()}
        />
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="select-year">Select year</InputLabel>
              <Select
                labelId="select-year"
                id="select-year-id"
                value={year}
                label="Year"
                onChange={handleYearChange}
              >
                <MenuItem value={"Select year"}>{"Select year"}</MenuItem>
                {yearArray.map((number) => {
                  return <MenuItem value={number}>{number}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          {loading && (
            <Grid item xs={8}>
              <CircularProgress />
            </Grid>
          )}
          {gallery.length > 0 && (
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select gallery name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAlbum}
                  label="Gallery"
                  onChange={handleSelectedGallery}
                  disabled={gallery.length < 1}
                >
                  {gallery &&
                    gallery?.map((item: any) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {year && selectedAlbum && !loading && (
            <Grid item xs={4}>
              <Button variant="contained" onClick={() => getAlbum()}>Download celebration</Button>
            </Grid>
          )}

        </Grid>
      </Box>
    </>
  );
};

export default HeaderComp;
